html,
body {
  height: 100vh;
  height: 100dvh;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.custom-y-padding-bottom .MuiChartsAxis-directionY .MuiChartsAxis-label {
  transform: translateX(-10px) !important;
}
